<template>
    <DxScrollView
        id="scoutingScrollView"
        ref="scrollView"
        :elementAttr="scrollListAttributes"
        :use-native="true"
        @reach-bottom="reachBottom"
        @pull-down="pullDown">
        <div class="scouting_list_data_holder">
            <div v-if="scouting_list == null || scouting_list == undefined || scouting_list.length <= 0">Nu există avertizări</div>
            <div v-for="(scouting_list_data, d_idx) in scouting_list" :key="d_idx">
                <div class="scouting_data">{{ scouting_list_data.data }}</div>
                <div class="scouting_list_holder">
                    <div v-for="(scouting_data, s_idx) in scouting_list_data.scouting_list" :key="s_idx" :class="scouting_data.is_active == 0 ? 'scouting_item' : 'scouting_item_active'" @click="onClickScouting(scouting_data.uuid)">
                        <div class="scouting_image_holder">
                            <img class="scouting_image" :src="scouting_data.images[0].image_url" v-if="scouting_data.images && scouting_data.images.length > 0"/> <!-- showScoutingDetails / onclickScouting -->
                            <img class="scouting_image" src="@/assets/images/test_img.png" v-else/>
                        </div>
                        <div class="scouting_item_data">
                            <div class="scouting_disieases">{{ scouting_data.diseases.map(obj => obj.name).join() }}</div>
                            <div class="scouting_priority_tag">
                                <div class="success_scouting_tag">{{ getPriorityText(scouting_data.priority) }}</div>
                            </div>
                            <div class="scouting_parcel_name">{{ scouting_data.parcel_name }} - {{ scouting_data.notes }}</div>
                        </div>
                        <div class="scouting_details_button_holder">
                            <img class="scouting_details_img" v-show="!edit_mode" src="@/assets/images/icons/arrow_right.svg"/>
                            <img class="scouting_details_img" v-show="edit_mode" src="@/assets/images/icons/edit.svg"/>
                        </div>
                    </div>
                </div>
            </div>
            <CreateScouting :show-popup="createScoutingVisibility" @hidden="hideCreateScouting" @refresh="refreshAfterCreate"></CreateScouting>
            <EditScouting :show-popup="scoutingEditVisibility" @hidden="hideScoutingEdit" :scouting_uuid="selected_scouting_uuid" @refresh="refreshAfterCreate"></EditScouting>
            <ScoutingDetails :show-popup="scoutingDetailsVisibility" @hidden="hideScoutingDetails" :scouting_uuid="selected_scouting_uuid" ></ScoutingDetails>
        </div>
    </DxScrollView>
</template>

<script>

import { mapGetters, mapActions } from "vuex";
import { DxScrollView } from "devextreme-vue/scroll-view";
//import { scouting_list } from "../testData"
import CreateScouting from "../dialogs/create-scouting.vue"
import EditScouting from "../dialogs/edit-scouting.vue"
import ScoutingDetails from "../dialogs/scouting-details.vue"

export default {
    name: "ScoutingList",
    props:{
        fillAtMount: {
            type: Boolean,
            default: true,
        },
      edit_mode: {
        type: Boolean,
        default: false,
      },
        filterData: {
            type: Object,
            default: null
        },
        season_on_parcel_uuid: {
            type: String,
            default: ''
        },
        parcel_uuid: String,
    },
    data(){
        return {
            createScoutingVisibility: false,
            scrollListAttributes: {
                class: 'scouting_list_scroller'
            },
            pagination: null,
            scrollOffset_top: 0,
            scouting_list: [],
            scoutingEditVisibility: false,
            scoutingDetailsVisibility: false,
            selected_scouting_uuid: '',
            executeFillScouting: true
        };
    },
    components: {
        DxScrollView,
        CreateScouting,
        EditScouting,
        ScoutingDetails
    },
    watch: {
        season_on_parcel_uuid: {
             handler: function (oldVal, newVal) {
                if(oldVal != newVal){
                    //this.loadScoutingData();
                    this.fillScouting(1, this.filterData, this.fillCallback);
                }
            }
        },
    },
    computed:{
        // scouting_list() {
        //     return scouting_list;
        // },

    },
    methods: {
        ...mapActions({
            getScoutings: "scouting/getScoutings",
        }),
        fillFirstPage(){
            this.fillScouting(1, null, this.fillCallback);
        },
        onClickScouting(scouting_uuid){
            if(this.edit_mode){
              this.showScoutingEdit(scouting_uuid);
            } else {
              this.showScoutingDetails(scouting_uuid);
            }
        },
        showScoutingDetails(scouting_uuid){
            this.selected_scouting_uuid = scouting_uuid;
            this.scoutingDetailsVisibility = true;
        },
        hideScoutingDetails(){
            this.scoutingDetailsVisibility = false;
        },
        showScoutingEdit(scouting_uuid){
          console.log('scouting_uuid edit -> ', scouting_uuid);
          this.selected_scouting_uuid = scouting_uuid;
          this.scoutingEditVisibility = true;
        },
        hideScoutingEdit(){
          this.scoutingEditVisibility = false;
        },
        getPriorityText(priorityNumber){
            if(priorityNumber == 1){
                return "Low"
            }
            if(priorityNumber == 2){
                return "Medium"
            }
            if(priorityNumber == 3){
                return "High"
            }
        },
        reachBottom(){
            console.log('reach bottom');
            if(this.pagination.current_page < this.pagination.last_page){
                this.fillScouting(this.pagination.current_page + 1, this.filterData, this.fillCallback);
            }
        },
        pullDown(event){
            console.log('pull down scouting list');
             //event.component.release();
             //this.fillScouting(1, this.filterData, this.fillCallback);
        },
        fillScouting(page_number, filter = null, callback = null){
            if(this.executeFillScouting){
                this.executeFillScouting = false;
                let limit = (this.$refs['scrollView'].instance._$element[0].clientHeight/75 + 1).toFixed(0);
                console.log('limit -> ', limit );
                limit = limit < 25 ? 25 : limit;
                console.log('fixed limit -> ', limit );
                if(page_number == 1){
                    this.scouting_list = [];
                }
                this.getScoutings({
                    season_on_parcel_uuid: this.season_on_parcel_uuid,
                    parcel_uuid: this.parcel_uuid,
                    page: page_number,
                    limit: limit
                }).then(response => {

                    response.data.forEach((sc) => {
                        let checkObject_date = this.scouting_list.find((obj) => obj.data == sc.reported_at.substring(0, sc.reported_at.indexOf(' ')));
                        if(checkObject_date == undefined){
                            checkObject_date = {
                                data: sc.reported_at.substring(0, sc.reported_at.indexOf(' ')),
                                scouting_list: []
                            };
                            checkObject_date.scouting_list.push(sc)
                            this.scouting_list.push(checkObject_date);
                        }
                        else{
                            checkObject_date.scouting_list.push(sc)
                        }
                    });
                    this.pagination = response.pagination;

                    if(callback != null){
                        callback();
                    }

                    this.executeFillScouting = true;
                });
            }
        },
        fillCallback(){
            console.log('fillCallback -> ', this.pagination);
            if(this.pagination){
                if(this.pagination.current_page == this.pagination.last_page || this.scouting_list.length == 0){
                    this.$refs['scrollView'].instance.release(true);
                }
                else{
                    this.$refs['scrollView'].instance.release(false);
                }
                this.$refs['scrollView'].instance.update();
                if(this.pagination.current_page == 1){
                    this.$refs['scrollView'].instance.scrollTo(0)
                }
            }
        },
        showCreateScouting(){
            this.createScoutingVisibility = true;
        },
        hideCreateScouting(){
            this.createScoutingVisibility = false;
        },
        refreshAfterCreate(){
            this.fillScouting(1, this.filterData, this.fillCallback);
        },
        loadScoutingData(){
            this.getScoutings({
            season_on_parcel_uuid: this.season_on_parcel_uuid,
            parcel_uuid: this.parcel_uuid
        }).then(response => {
            this.scouting_list = [];
            response.data.forEach((sc) => {
                let checkObject_date = this.scouting_list.find((obj) => obj.data == sc.reported_at.substring(0, sc.reported_at.indexOf(' ')));
                if(checkObject_date == undefined){
                    checkObject_date = {
                        data: sc.reported_at.substring(0, sc.reported_at.indexOf(' ')),
                        scouting_list: []
                    };
                    checkObject_date.scouting_list.push(sc)
                    this.scouting_list.push(checkObject_date);
                }
                else{
                    checkObject_date.scouting_list.push(sc)
                }
            });

        });
        }
    },
    mounted(){}
}
</script>

<style lang="scss">
    @import "@/assets/scss/scoutingMobile/scoutingList.scss";
</style>
