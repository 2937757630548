<template>
        <DxScrollView ref="scrollView" :elementAttr="scrollListAttributes" :use-native="true" @reachBottom="reachBottom" @scroll="scroll" reachBottomText="Se încarcă..." >
            <div class="rent_list_holder">
                <div class="no_dataLabel" v-if="noContractData">Nu există nici un contract</div>
                <div v-for="(rent_data, r_idx) in rent_list_data" :key="rent_data.idx_uuid">
                    <div  class="rent_item" :selected="rent_data.selected">
                        <DxCheckBox v-show="createSelectionMode || editSelectionMode || deleteSelectionMode" v-model="rent_data.selected" class="select_contract_checkbox" @valueChanged="contractSelectionChanged($event, rent_data, r_idx)"/>
                        <div class="rent_main_icon">
                            <div class="rent_main_icon_file" :selected="rent_data.selected">
                                <img src="@/assets/images/icons/file.svg"/>
                            </div>
                        </div>
                        <div class="rent_main_info">
                            <div class="company_name">{{rent_data.name}}
                            </div>
                            <div class="rent_tags">
                                <div @click="expandContract(rent_data, r_idx)" :class="[{'warning_rent_tag': rent_data.status_for_all_years != 'paid'}, {'success_rent_tag': rent_data.status_for_all_years == 'paid'}]" :selected="rent_data.selected">{{getStatusName(rent_data.status_for_all_years)}}</div>
                            </div>
                            <div class="contract_holder">
                                <div class="contract">Nr. contract: {{rent_data.contract.contract_number}}</div>
                                <div v-show="false" class="more_contracts" v-if="rent_data.contracts && rent_data.contracts.length > 1"> + {{rent_data.contracts.length - 1}} more</div>
                            </div>
                        </div>
                        <div class="expand_icon_holder">
                            <div :class="rent_data.expanded ? 'expand_icon_expanded' : 'expand_icon'" @click="expandContract(rent_data, r_idx)" :selected="rent_data.selected">
                                <img v-if="rent_data.expanded" src="@/assets/images/icons/arrow_up_green.svg"/>
                                <img v-else src="@/assets/images/icons/arrow_down.svg"/>
                            </div>
                        </div>
                    </div>
                    <contract-details
                        :ref="'contractDetails_' +  r_idx"
                        v-show="rent_data.expanded"
                        :paymentMode="paymentMode"
                        :createSelectionMode="createSelectionMode"
                        :templateData="rent_data"
                        :editSelectionMode="editSelectionMode"
                        :deleteSelectionMode="deleteSelectionMode"
                        @handleParentSelection="handleParentSelection"></contract-details>
                </div>
            </div>
        </DxScrollView>
</template>

<script>

import { mapGetters, mapActions } from "vuex";
import Vue from "vue";
import infiniteScroll from "vue-infinite-scroll";
Vue.use(infiniteScroll);
import ContractDetails from './contractDetails.vue';
import { DxCheckBox } from 'devextreme-vue/check-box';
import { DxScrollView } from "devextreme-vue/scroll-view";
import { v4 } from 'uuid';

export default {
    name: "ContractPaymentList",
    props: {
        createSelectionMode: {
            type: Boolean,
            default: false,
        },
        paymentMode: {
            type: Boolean,
            default: false,
        },
         fillAtMount: {
            type: Boolean,
            default: true,
        },
        filterData: {
            type: Object,
            default: null
        },
        editSelectionMode: Boolean,
        deleteSelectionMode: Boolean
    },
    data(){
        return {
            noContractData: false,
            executeFillContract: true,
            filterTimer: {},
            filterTimerInterval: 500,
            rent_list_data: [],
            pagination: {},
            scrollOffset_top: 0,
            scrollListAttributes: {
                class: 'rent_list_scroller'
            },
            allowChildSelectionHandling: true
        };
    },
    computed: {
        ...mapGetters({
            currentDepartment: "auth/currentDepartment"
        }),
    },
    watch: {
        createSelectionMode(newVal){
            if(newVal == false){
                this.selectAll(false);
            }
        },
        editSelectionMode(newVal){
            if(newVal == false){
                this.selectAll(false);
            }
        },
        filterData: {
             handler: function (oldVal, newVal) {
                if(newVal != null && this.fillCallback instanceof Function){
                    this.fillContracts(1, newVal, this.fillCallback);
                }
            },
            deep: true,
        },
        scrollOffset_top:{
            handler: function(newVal, oldVal){
                if(newVal < oldVal){
                    this.$emit('scrollDown');
                }
                if(newVal > oldVal){
                    this.$emit('scrollUp');
                }
            }
        }
    },
    components: {
        ContractDetails,
        DxCheckBox,
        DxScrollView
    },
    methods: {
         ...mapActions({
            getContracts: "leasecontracts/getOwnersForPayments",
        }),
        selectAll(select){
            this.rent_list_data.forEach((obj) => {
                obj.selected = select;
            });
        },
        expandAll(expand){
            this.rent_list_data.forEach((obj, idx) => {
                obj.expanded = expand;
                 if(!expand){
                this.$refs['contractDetails_' + idx][0].expanded = false;
            }
            });
        },

         expandContract(rent_data, idx){
            rent_data.expanded = !rent_data.expanded;
            if(this.paymentMode){
                this.$refs['contractDetails_' + idx][0].expandPyamentDetails();
            }
            if(!rent_data.expanded){
                this.$refs['contractDetails_' + idx][0].expanded = false;
            }
        },
        selectContract(selected, rent_data, r_idx){

        },
        fillContracts(page_number, filter = null, callback = null){
            if(this.executeFillContract){
                filter = filter ?? {};
                filter.page = page_number;
                this.getContracts(filter).then((response) => {
                    if(page_number == 1){
                        this.rent_list_data = [];
                        this.$set(this.rent_list_data, [] );
                    }
                    response.data.forEach((contract) => {
                        let hasAnyPaid = false;
                        let hasAnyPartiallyPaid = false;
                        let hasAnyUnPaid = false;
                        
                        contract.status_for_all_years = 'paid';
                        contract.selected = false;
                        contract.expanded = false;
                        contract.idx_uuid = v4();
                        contract.years.sort((a,b) => (a.year > b.year) ? 1 : ((b.year > a.year) ? -1 : 0))
                        contract.years.forEach(y => {
                            if(y.debt > 0){
                                y.debt = Number(y.debt.toFixed(2));
                            }
                            if(y.total_value > 0){
                                y.total_value = Number(y.total_value.toFixed(2));
                            }
                            y.status = y.debt == 0 ?  'paid' : (y.debt < y.total_value ? 'partially_paid' : 'unpaid');
                            if(y.debt == null || y.total_value == null ){
                                hasAnyUnPaid = true;
                            }
                            else if(y.status == 'paid'){
                                hasAnyPaid = true;
                            }
                            else if(y.status == 'partially_paid'){
                                hasAnyPartiallyPaid = true;
                            }
                            else{
                                hasAnyUnPaid = true;
                            }
                        });
                        contract.status_for_all_years = 'unpaid';
                        if(hasAnyUnPaid && !hasAnyPaid && !hasAnyPartiallyPaid){
                            contract.status_for_all_years = 'unpaid'
                        }
                        else if(hasAnyPartiallyPaid || (hasAnyUnPaid && hasAnyPaid) ){
                            contract.status_for_all_years = 'partially_paid'
                        }
                        else if(hasAnyPaid && !hasAnyUnPaid && !hasAnyPartiallyPaid){
                            contract.status_for_all_years = 'paid'
                        }
                        this.rent_list_data.push(contract);
                        this.$set(this.rent_list_data, this.rent_list_data.length - 1, contract );
                    });
                    this.pagination = response.pagination;
                    this.noContractData = this.rent_list_data.length == 0;
                    if(callback != null){
                        callback();
                    }
                });
            }
            this.$set(this, 'executeFillContract', true);
        },
        fillCallback(){
            if(this.pagination?.current_page == this.pagination?.last_page || this.rent_list_data.length == 0){
                this.$refs['scrollView'].instance.release(true);
            }
            else{
                this.$refs['scrollView'].instance.release(false);
            }
            this.$refs['scrollView'].instance.update();
            if(this.pagination?.current_page == 1){
                this.$refs['scrollView'].instance.scrollTo(0)
            }
        },
        reachBottom(){
            if(this.pagination?.current_page < this.pagination?.last_page){
                this.fillContracts(this.pagination?.current_page + 1, null, this.fillCallback);
            }
        },
        scroll(event){
            this.scrollOffset_top = event.scrollOffset.top;
        },
        pullDown(event){
             event.component.release();
        },
        statusTagClick(status_filter){
            this.$emit('status_filter', status_filter);
        },
        contractSelectionChanged(option, contract, idx){
            if(this.allowChildSelectionHandling){
                if(this.$refs['contractDetails_' + idx] && this.$refs['contractDetails_' + idx].length > 0){
                    this.$refs['contractDetails_' + idx][0].selectAllPaymentYears(option.value);
                }
                contract.years.forEach((year) => {
                    year.selected = option.value;
                });
            }
            this.allowChildSelectionHandling = true;
        },
        handleParentSelection(hasAnySelected, idx_uuid, selected, year_uuid){
            let checkData = this.rent_list_data.find(obj => obj.idx_uuid == idx_uuid);
            if(checkData){
                if(hasAnySelected){
                    if(!checkData.selected){
                        this.allowChildSelectionHandling = false;
                        checkData.selected = true;
                    }
                }
                else{
                    if(checkData.selected){
                        this.allowChildSelectionHandling = false;
                        checkData.selected = false;
                    }
                }
                let checkYear = checkData.years.find(y => y.uuid == year_uuid);
                if(checkYear){
                    checkYear.selected = selected;
                }
            }

        },
        getSelectedContracts(){
            let filteredList = this.rent_list_data.filter(obj => obj.selected == true);
            filteredList.forEach(c => {
                c.years = c.years.filter(obj => obj.selected == true);
            });
            
            return filteredList;
        },
        getSelectedPayments(){
            let paymentArray = []
            this.rent_list_data.forEach((data, idx) => {
                if(this.$refs["contractDetails_"+idx] && this.$refs["contractDetails_"+idx][0]){
                    let tmpArray = this.$refs["contractDetails_"+idx][0].getSelectedPayments();
                    if(tmpArray && tmpArray.length > 0){
                        paymentArray = [...paymentArray, ...tmpArray];
                    }
                }
            });
            return paymentArray;
        },
        getStatusName(name){
            if(name == 'paid'){
                return "Plătit";
            }
            else if(name == 'partially_paid'){
                return "plătit parțial"
            }
            else if(name == 'unpaid'){
                return "neplătit"
            }
            else{
                return name;
            }
        },
        refreshFromOutside(){
            this.fillContracts(1, null, this.fillCallback);
        }
    },
    mounted(){
        if(this.fillAtMount){
            this.fillContracts(1, null, this.fillCallback);
        }
    }
}
</script>
